<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-fluid">
      <a href="/" v-on:click="back" class="btn" v-if="store.showBackButton">
        &larr; Voltar para o início
      </a>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        {{headerTitle}}
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item d-none d-md-flex me-3">
          <div class="btn-list"></div>
        </div>
        <div class="d-none d-md-flex"></div>
        <div class="nav-item dropdown">
          <a
            href="#"
            class="nav-link d-flex lh-1 text-reset p-0"
            data-bs-toggle="dropdown"
            aria-label="Open user menu"
            aria-expanded="false"
          >
            <span class="nav-link-icon">
              <i class="ti ti-user-circle text-lg"></i>
            </span>
            <div class="d-none d-xl-block ps-2">
              <div><small class="text-blue">{{user.firstname}}</small></div>
              <div class="mt-1 small text-muted">{{user.email}}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="/user/profile" class="dropdown-item">Meu perfil</a>
            <div class="dropdown-divider"></div>
            <a href="#" v-on:click="logout" class="dropdown-item">Sair</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import EventBus from "../services/event-bus";
import { store } from "../store";

export default {
  name: "Header",
  data() {
    return {
      headerTitle: "",
      user: {},
      customer: {},
      store: store
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.customer = this.user.customer;
    }
    EventBus.on('set-header-title', (title) => {
      this.headerTitle = title;
    });
  },
  methods: {
    logout() {
      EventBus.emit("call-logout");
    },
    back() {
      history.back();
    }
  },
};
</script>