<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-flow-template-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog">
            <div
                class="modal-dialog modal-xl modal-dialog-centered"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{actionTitle}}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="flowTemplate.enabled"
                                />
                                <span class="form-check-label">Publicado</span>
                            </label>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Nome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="flowTemplate.name"
                                    placeholder="Nome do template"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Descrição</label>
                                <textarea class="form-control" v-model="flowTemplate.description" rows="5"></textarea>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Tags</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="tags_input"
                                    placeholder="Tags do template"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Imagem</label>
                                <input
                                    type="file"
                                    class="form-control"
                                    @change="updateImage"
                                    placeholder="Imagem do template" />
                                <span class="form-description">Usar 640x480 pixels</span>
                            </div>
                        </div>
                        <div class="row mb-3" v-if="image_preview">
                            <div class="col-6 p-2">
                                <label class="form-label">Preview</label>
                                <div class="card">
                                    <img v-if="image_preview" v-bind:src="image_preview" alt="Preview da imagem">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">JSON do fluxo</label>
                                <textarea class="form-control" v-model="flowTemplate.data" rows="10"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-link link-secondary" data-bs-dismiss="modal" @click="cancel()">
                            Cancelar
                        </button>
                        <button class="btn btn-primary ms-auto" data-bs-dismiss="modal" @click="save()">
                            <i class="ti ti-plus"></i>
                            Salvar template
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlowTemplateForm',
    props: {
        action: {
            type: String,
            required: true,
            default: 'create'
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data(){
        return {
            actionTitle: 'Criar template',
            flowTemplate: {
                enabled: false,
                tags: []
            },
            tags_input: '',
            image_preview: ''
        }
    },
    async mounted(){
        if(this.action === 'edit'){
            this.actionTitle = 'Editar template'
        }

        this.$watch('data', (newData) => {
            if (this.action == 'edit') {
                this.flowTemplate = structuredClone(newData)
            } else {
                this.flowTemplate = {
                    active: false
                }
            }

            this.tags_input = this.flowTemplate.tags ? this.flowTemplate.tags.join(', ') : ''
            this.image_preview = this.flowTemplate.imageData ?? ''
        })

        this.$watch('tags_input', (newData) => {
            this.flowTemplate.tags = newData.split(',').map(tag => tag.trim())
        })
    },
    methods: {
        cancel(){
            this.$emit('cancel')
        },
        save(){
            this.$emit('save', this.flowTemplate)
        },
        updateImage(event) {            
            if (event.target.files.length !== 0) {
                var reader  = new FileReader();
                reader.onloadend = function () {
                    this.image_preview = reader.result
                    this.flowTemplate.imageData = reader.result
                }.bind(this)
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    }
}
</script>