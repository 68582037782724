<template>
        <div>
        <div class="card" v-if="workspacesList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-workspaces"></i>
                        Workspaces
                    </h3>
                </div>
                <div class="col-auto">
                    <button @click="newWorkspace()" data-bs-target="#modal-workspace-form" data-bs-toggle="modal" class="btn btn-primary" v-if="user.is_admin">
                        <i class="ti ti-plus"></i>
                        Criar workspace
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Cliente</th>
                            <th>Status</th>
                            <th>IDs</th>
                            <th>URLs</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="workspace in workspacesList" v-bind:key="workspace._id">
                            <td>{{ workspace.name }}</td>
                            <td>{{ workspace.customer.name }}</td>
                            <td>
                                <span v-if="workspace.enabled" class="badge bg-success-lt">Habilitado</span>
                                <span v-else class="badge bg-danger-lt">Desabilitado</span>
                            </td>
                            <td>
                                <small>
                                    Customer ID: <code>{{ workspace.customer._id }}</code> <br/>
                                    Workspace ID: <code>{{ workspace._id }}</code> <br />
                                    Engine Token: <code>{{ workspace.engine_token }}</code>
                                </small>
                            </td>
                            <td>
                                Frontend URL: <a v-bind:href="workspace.engine_frontend_url" target="_blank">{{ workspace.engine_frontend_url }}</a> <br />
                                Backend URL: <a v-bind:href="workspace.engine_backend_url" target="_blank">{{ workspace.engine_backend_url }}</a> <br />
                                Engine URL: <a v-bind:href="workspace.engine_url" target="_blank">{{ workspace.engine_url }}</a> <br />
                            </td>
                            <td>{{ formatDate(workspace.updated_at) }}</td>
                            <td>
                                <button @click="editWorkspace(workspace)" class="btn btn-sm" data-bs-target="#modal-workspace-form" data-bs-toggle="modal" v-if="user.is_admin">
                                    <i class="ti ti-pencil"></i>
                                    Editar
                                </button>
                                <span>&nbsp;</span>
                                <a class="btn btn-sm" v-bind:href="'/customers/workspaces/delete/' + workspace._id" v-if="user.is_admin">
                                    <i class="ti ti-trash"></i>
                                    Remover
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong>{{ workspacesList.length }} workspaces</strong>
                <br />
            </div>
        </div>

        <div class="card" v-if="!workspacesList.length">
            <div class="card-header">
                <h3 class="card-title">
                    Workspaces de clientes
                </h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">
                        Nenhuma conta criada ainda.
                    </p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar uma nova conta
                    </p>
                    <div class="empty-action">
                        <button @click="newWorkspace()" data-bs-target="#modal-workspace-form" data-bs-toggle="modal" class="btn btn-primary" v-if="user.is_admin">
                            <i class="ti ti-plus"></i>
                            Criar workspace
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <WorkspaceForm :action="workspaceForm" :data="workspaceFormData" @save="saveWorkspace" />
    </div>
</template>

<script>
import Api from '../services/api'
import EventBus from '../services/event-bus'
import WorkspaceForm from '../components/WorkspaceForm'
import { store } from '../store';

export default {
    name: 'WorkspacesPage',
    components: {
        WorkspaceForm
    },
    data() {
        return {
            workspaceForm: 'create',
            workspaceFormData: null,
            workspacesList: [],
            user: {}
        }
    },
    async mounted() {
        store.showSidebar = true;
        this.user = store.user
        await this.loadworkspaces()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadworkspaces(){
            await Api.customers.workspaces.all()
                .then(response => {
                    this.workspacesList = response.data
                })
        },
        newWorkspace() {
            this.workspaceForm = 'create'
            this.workspaceFormData = {}
        },
        editWorkspace(workspace) {
            this.workspaceForm = 'edit'
            this.workspaceFormData = workspace
        },
        async saveWorkspace(data) {
            try {
                if (this.workspaceForm == 'create') {
                    data.workspace = this.workspace_id
                    await Api.customers.workspaces.create(data)
                } else {
                    await Api.customers.workspaces.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Workspace salvo com sucesso",
                });

                await this.loadworkspaces()
            } catch(e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o workspace",
                });
            }
        }
    }
}
</script>