<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">Remover usuário</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover o usuário <strong>{{customerUser.firstname}}</strong>? <br>
                                    <span>Esta ação não poderá ser desfeita.</span>
                                </p>
                                <div class="form-group">
                                    <label class="form-label">Para confirmar a remoção, digite abaixo o texto <span class="bg-red-lt px-1">{{customerUser.firstname}}</span>:</label>
                                    <input type="text" class="form-control" v-model="customerUserNameConfirmation">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a href="/customers/users" class="btn btn">
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a v-on:click="removeCustomerUser" class="btn btn-danger">
                                    <i class="ti ti-trash"></i>
                                    Remover a conta do cliente
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api";
import EventBus from "../services/event-bus";

export default {
    name: "CustomerUserDeletePage",
    data() {
        return {
            customerUserNameConfirmation: "",
            customerUser: {}
        };
    },
    async mounted() {
        var id = this.$route.params.id;
        var response = await Api.customers.users.get(id);
        this.customerUser = response.data;
    },
    methods: {
        async removeCustomerUser() {
            var id = this.$route.params.id;
            try {
                if (this.validate()){
                    await Api.customers.users.delete(id);

                    this.$router.push("/customers/users");

                    EventBus.emit('message', {
                        type: 'success',
                        message: 'Usuário removido com sucesso!'
                    })
                }
            } catch (error) {
                EventBus.emit('message', {
                    type: 'error',
                    message: 'Erro ao remover o usuário:' + error
                })
            }
        },
        validate(){
            if (this.customerUser.firstname !== this.customerUserNameConfirmation){
                EventBus.emit('message', {
                    type: 'warning',
                    message: 'O nome do usuário não confere com o nome digitado!'
                })

                return false;
            }
            return true;
        }
    }
}
</script>