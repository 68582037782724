import axios from 'axios';
import EventBus from './event-bus';

const API_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = API_URL;
axiosInstance.interceptors.request.use((request) => {
    if (localStorage.getItem('token')) {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }
    return request
})

// redirect to login page when receiving 403 response
axiosInstance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 403) {
        EventBus.emit('call-logout')
    }

    EventBus.emit('ajax-request-end');
    return Promise.reject(error)
})


// call event bus when ajax start
axiosInstance.interceptors.request.use((request) => {
    EventBus.emit('ajax-request-start');
    return request
})

// call event bus when ajax end
axiosInstance.interceptors.response.use((response) => {
    EventBus.emit('ajax-request-end');
    return response
})

export default {
    /**
     * Authenticate user and generate token
     * @param {String} email 
     * @param {String} password 
     * @returns {Promise<Object>}
     */
    authenticate (email, password) {
        return axiosInstance.post('v1/admin/authenticate', { email, password });
    },

    /**
     * Users operations
     */
    users: {
        /**
         * Get current user data
         * @returns {Promise<Object>}
         */
        me: () => {
            return axiosInstance.get('v1/admin/users/me');
        },

        /**
         * Update current user data
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        updateMe: (data) => {
            return axiosInstance.put('v1/admin/users/me', data);
        },

        /**
         * Get all users
         * @returns {Promise<Object>}
         */
        all: () => {
            return axiosInstance.get('v1/admin/users?limit=1000');
        },

        /**
         * Get user by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/admin/users/' + id);
        },

        /**
         * Create user
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/admin/users', data);
        },

        /**
         * Update user
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/admin/users/' + id, data);
        },

        /**
         * Delete user
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/admin/users/' + id);
        }
    },

    /**
     * Customers operations
     */
    customers: {
        /**
         * Get all workspaces
         * @returns {Promise<Object>}
         */
        all: () => {
            return axiosInstance.get('v1/admin/customers');
        },

        /**
         * Get workspace by id
         */
        get: (id) => {
            return axiosInstance.get('v1/admin/customers/' + id);
        },

        /**
         * Create new workspace
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/admin/customers', data);
        },

        /**
         * Update workspace
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/admin/customers/' + id, data);
        },

        /**
         * Delete workspace
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/admin/customers/' + id);
        },

        /**
             * Get customer.company data
             */
         getCompanyData(id) {
            return axiosInstance.get('v1/admin/customers/company/' + id);
        },

        /**
         * Update customer.company data
         */
        updateCompanyData(id, data) {
            return axiosInstance.put('v1/admin/customers/company/' + id, data);
        },

        /**
         * Customer users operations
         */
        users: {
            /**
             * Get all users
             * @returns {Promise<Object>}
             */
            all: () => {
                return axiosInstance.get('v1/admin/customers/users?limit=1000');
            },

            /**
             * Get user by id
             * @param {String} id
             * @returns {Promise<Object>}
             */
            get: (id) => {
                return axiosInstance.get('v1/admin/customers/users/' + id);
            },

            /**
             * Create user
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            create: (data) => {
                return axiosInstance.post('v1/admin/customers/users', data);
            },

            /**
             * Update user
             * @param {String} id
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            update: (id, data) => {
                return axiosInstance.put('v1/admin/customers/users/' + id, data);
            },

            /**
             * Delete user
             * @param {String} id
             * @returns {Promise<Object>}
             */
            delete: (id) => {
                return axiosInstance.delete('v1/admin/customers/users/' + id);
            },

            /**
             * Get user's token to login as user
             * @param {String} id 
             * @returns 
             */
            loginAsCustomer(id) {
                return axiosInstance.get('v1/admin/login-as-customer/' + id);
            }
        },

        /**
         * Customers workspaces operations
         */
        workspaces: {
            /**
             * Get all workspaces
             * @returns {Promise<Object>}
             */
            all: () => {
                return axiosInstance.get('v1/admin/customers/workspaces');
            },

            /**
             * Get workspace by id
             */
            get: (id) => {
                return axiosInstance.get('v1/admin/customers/workspaces/' + id);
            },

            /**
             * Create new workspace
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            create: (data) => {
                return axiosInstance.post('v1/admin/customers/workspaces', data);
            },

            /**
             * Update workspace
             * @param {String} id
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            update: (id, data) => {
                return axiosInstance.put('v1/admin/customers/workspaces/' + id, data);
            },

            /**
             * Delete workspace
             * @param {String} id
             * @returns {Promise<Object>}
             */
            delete: (id) => {
                return axiosInstance.delete('v1/admin/customers/workspaces/' + id);
            }
        },
    },

    monitoring: {
        /**
         * Get all flows logs (logs generated by user nodes)
         * @returns {Promise<Object>}
         */
         getAllLogs: (page = 1, size = 20, filters = {}) => {
            var params = {
                page: page,
                size: size,
                filters: filters
            }
            return axiosInstance.get('v1/admin/monitoring/logs', { params });
        }
    },

    /**
     * Flow templates operations
     */
    flowTemplates: {
        /**
         * Get all flow templates
         * @returns {Promise<Object>}
         */
        all: () => {
            return axiosInstance.get('v1/admin/flow-templates?limit=1000');
        },

        /**
         * Get template by id
         */
        get: (id) => {
            return axiosInstance.get('v1/admin/flow-templates/' + id);
        },

        /**
         * Create new template
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/admin/flow-templates', data);
        },

        /**
         * Update template
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/admin/flow-templates/' + id, data);
        },

        /**
         * Delete template
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/admin/flow-templates/' + id);
        }
    }
}