<template>
    <h2 class="page-title">
        <a href="/">
            <img src="@/assets/logo-white.svg" class="logo" width="160" alt="Floui.io - Integração sem limites">
            <small>Administração Floui</small>
        </a>
    </h2>
</template>
<style scoped>
.page-title small {
    margin: 10px 0 10px 10px;
    display: block;
    text-align: center;
    background: orange;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
}
.page-title a {
    text-decoration: none;
}
</style>